<template lang="html">
  <div v-if="!loading">
    <DlgHeader>
      <IxRes>dlgCert.frs.header</IxRes>
    </DlgHeader>
    <p>
      <IxRes>dlgCert.frs.header.info</IxRes>
    </p>
    <SimpleTable
      class="nrc-table"
      :columns="columns" localize-headers
      :data="data.fieldsMissingNrc"
    >
      <template #name="{value}">
        <strong>{{ value }}</strong>
      </template>
      <template #link="{row: {id}}">
        <a :href="`/FieldRecordSystem/${$route.params.year}/field/${id}`">
          <IxRes>dlgCert.frs.buttons.linkToFrsField</IxRes>
        </a>
      </template>
    </SimpleTable>
    <div class="organic-n-fertilizer">
      <LabelAndMessage class="label-and-message">
        <template #label>
          <IxRes>dlgCert.frs.organicNFertilizer</IxRes>:
        </template>
        <template #description>
          <IxRes :context="{calculatedValue: formattedPreviousFertilization}">dlgCert.frs.info.nFertilizer</IxRes>
        </template>
        <NumericInput
          v-model="manualPreviousOrganicNitrogenFertilization"
          class="dlg-input"
          name="manualPreviousOrganicNitrogenFertilization"
          addon="kgN/ha"
          :placeholder="formattedPreviousFertilization"
        />
      </LabelAndMessage>
      <IxButton
        v-if="manualPreviousOrganicNitrogenFertilization !== null"
        class="reset-button"
        large :icon="icons.reset"
        @click="manualPreviousOrganicNitrogenFertilization = null"
      >
        <IxRes
          :context="{calculatedValue: formattedPreviousFertilization}"
        >
          dlgCert.frs.buttons.resetPreviousOrganicFertilization
        </IxRes>
      </IxButton>
    </div>
    <HelpBox v-if="manualPreviousOrganicNitrogenFertilization === null">
      <IxRes
        :context="{calculatedValue: formattedPreviousFertilization}"
      >
        dlgCert.frs.hints.previousFertilizationUsesCalculatedValue
      </IxRes>
    </HelpBox>
    <HelpBox v-else type="warning">
      <IxRes
        :context="{calculatedValue: formattedPreviousFertilization}"
      >
        dlgCert.frs.hints.previousFertilizationUsesManualOverride
      </IxRes>
    </HelpBox>
  </div>
</template>

<script>
import {faRedo} from '@fortawesome/free-solid-svg-icons'

import SimpleTable from '@components/table/SimpleTable'
import HelpBox from '@components/help/HelpBox'
import IxButton from '@components/IxButton'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import NumericInput from '@components/forms/NumericInput'

import DlgHeader from './forms/elements/DlgHeader'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    DlgHeader,
    SimpleTable,
    NumericInput,
    IxButton,
    HelpBox,
    LabelAndMessage
  },
  mixins: [
    ApiMixin
  ],
  computed: {
    apiUrl () {
      const {orgUnitId, year} = this.$route.params

      return `/api/v2/dlg-cert/${orgUnitId}/frs/${year}`
    },
    icons () {
      return {
        reset: faRedo
      }
    },
    columns () {
      return {
        code: {title: 'dlgCert.frs.nrc.headers.code', sortable: true, type: 'string'},
        name: {title: 'dlgCert.frs.nrc.headers.name', sortable: true, type: 'string'},
        link: 'dlgCert.frs.nrc.headers.link'
      }
    },
    manualPreviousOrganicNitrogenFertilization: {
      get () {
        return this.data.manualPreviousOrganicNitrogenFertilization
      },
      set (value) {
        this.update({name: 'manualPreviousOrganicNitrogenFertilization', value})
      }
    },
    formattedPreviousFertilization () {
      return this.$i18n.format(this.data.calculatedPreviousOrganicNitrogenFertilization.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.nrc-table {
  width: 50%;
}
.organic-n-fertilizer{
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
}
.label-and-message {
  display: flex;
  margin-right: 1em;
  margin-top: 2px;
}
.dlg-input {
  margin-left: 1em;
  margin-top: -5px;
}
.reset-button {
  height: 29px;
}
</style>
